.Ended__Proof {
    text-align: right;
    font-family: 'Circular Std Bold';

    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.6px;
    line-height: 18px;

    color: #FDAC4C;
    text-transform: uppercase;
}