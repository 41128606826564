.Score {
    padding: 20px 64px;
    border-radius: 64px;

    color: white;
    background-color: #ffffff22;
}

.Score > * {
    font-size: 21px;
    line-height: 27px;
}

/* Label : "Votre score" */
.Score__Label {
    margin-bottom: 4px;
}

.Score__value {
    font-family: 'Circular Std Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}