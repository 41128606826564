.View__Content {
    display: block;
    position: relative;
    width: 100%;
    min-height: calc(100% - 66px);

    padding: 40px;

    border-radius: 0 48px 0 0;
    background-color: white;
}

@media all and (max-width: 500px) {
    .View__Content {
        display: flex;
        flex-direction: column;

        padding: 20px;
    }
}