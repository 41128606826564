.Choices {
    display: flex;
    flex-wrap: wrap;
}

.column {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto auto;
    justify-content: center;
}

.little_column {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    justify-content: center;
}

@media all and (max-width: 500px) {
    .column {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 4px 8px;
    }

    .little_column {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 4px 8px;
        justify-content: center;
    }
}