#View__the-list {
    background: linear-gradient(to right, #fa9d44 0%, #ffb852 100%);
}

.question {
    font-size: 21px;
    font-weight: bold;
}

.firstChar{
    display: inline;
    font-size: 23px;
    font-weight: 700;
    margin-right: 8px;
    margin-bottom: 0;
}

.maskedChar{
    display: inline-block;
    font-size: 23px;
}

input.inputB{
    display: flex;
    background-color: white;
    border-radius : 100px;
    border: 2px solid #FEB250;
    width: 100%;
    height: 53px;
    box-shadow: 0 0 15px #0000002c;
    margin: 25px 0;
    padding: 25px;
    font-family: "Circular Std Book";
    font-size: 16px;
    outline: none;
}

.TheList__Words {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px 12px;
}

.TheList__Word {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    border: 2px solid #EBEBEB;
    border-radius: 12px;

    text-transform: lowercase;
}

.center {
    margin: auto;
}

.answers {
    display: flex;
    flex-direction: column;
}

@media all and (max-width: 400px) {
    .TheList__Word { height: 40px; }
    .inputB {
        padding: 0 25px !important;
        margin: 16px auto !important;
        height: 42px !important;
    }

    .inputB:focus {
        font-size: 15px !important;
    }
}