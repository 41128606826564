.Progress {
    display: block;
    position: relative;
    height: 12px;

    border-radius: 12px;

    background: linear-gradient(to right, #4e2a7a 0%, #733db4 100%);
}

.Progress__Large {
    height: 16px;

    background: linear-gradient(to right, #fba246 0%, #feb551 100%);
}

.ProgressLabel {
    display: flex;
    flex-direction: row;
    width: 20px;
    position: absolute;
    right: 8px;

    font-size: 0.6em;

    color: white;
    top: 50%;
    transform: translateY(-50%);
}

.Progress__Large > .ProgressLabel {
    color: black;
    font-weight: 600;
    font-size: 0.7em;
}

.empty {
    left: 8px;
    color: black;
}

@media all and (max-width: 300px) {
    .ProgressLabel {
        left: 8px !important;
    }
}