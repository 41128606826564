.ProgressBar {
    display: block;
    position: relative;
    height: 12px;

    border-radius: 12px;

    width: 100%;
    margin-bottom: 24px;

    background-color: #eeeeee;
}

.ProgressBar__Large {
    height: 16px;
}