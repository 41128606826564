.play-screen {
    background: linear-gradient(49.75deg, #FBFBFB 0%, #E9F0F4 100%);
    display: flex;
    padding-bottom: 100px;
}

.play-label {
    text-transform: none;
    color: #FFFFFF;
    font-family: "Circular Std Bold";
    font-size: 16px;
    letter-spacing: 0.6px;
    line-height: 20px;
}

.play-icon {
    height: 230px;
    top: -90px;
    left: -50px;
}

.play-preview {
    width: 140px;
}

.play-splash-container {
    flex: 1;
    background-color: #E6E6E6;
    margin: 40px 0;
}

.play-splash {
    background-color: #E6E6E6;
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.play-button {
    margin: 0 auto 40px auto;
    width: 100% !important;
}

@media all and (max-width: 450px) {
    .play-label {
        font-size: 12px;
    }

    .play-button {
        margin: 0 auto 18px auto;
        min-height: inherit;
        height: 42px !important;
    }

    .exp-screen .Button {
        min-height: 48px !important;
        height: 48px !important;
    }
}