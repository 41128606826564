.clueCount {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;

    top: -9px;
    right: 30px;

    width: 20px;
    height: 20px;

    background: linear-gradient(315deg, #FFB852 0%, #FA9D44 100%);
    border: 1px solid black;
    color: white;
    border-radius: 20px;
}

@media all and (max-width: 350px) {
    .help {
        font-size: 11px !important;
        height: 32px;
    }
}