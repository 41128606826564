.Text__Line {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    margin-bottom: 14px;

    font-size: 19px;
    line-height: 24px;
    color: #4f4f4f;
}

.Text__Line span {
    margin: 0 8px;
}

.Text__Line span:first-of-type {
    margin-left: 0;
}

.Text__Line span:last-of-type {
    margin-right: 0;
}

@media all and (max-width: 500px) {
    .Text__Line {
        font-size: 15px;
        margin-bottom: 8px;
    }
}