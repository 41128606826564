.Popup {
    display: block;
    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    height: 100%;

    background-color: #00000055;
    z-index: 999999;
}

.Popup__Mask {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;

    width: 100%;
    height: 70%;
    min-height: 480px;
    padding: 84px;
    z-index: 999999;

    top: auto;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: 60px 60px 0 0;
    background-color: white;
}

.Popup__large > .Popup__Mask {
    height: 90%;
}

.spaced {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
