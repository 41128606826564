.Card__Preview {
    display: block;
    width: 120px;
    height: 100%;
}

.Card__mask {
    position: absolute;

    width: 160px;
    height: 160px;

    transform: translateX(-38%) translateY(38%);

    left: 0;
    right: auto;
    top: auto;
    bottom: 0;

    border-radius: 50%;
    background-color: #eef3f6;
}

.Card__Preview > img {
    position: absolute;
    width: fit-content;
    top: calc(50% + 15px);
    left: -12px;
    transform: translateY(-50%);
}

@media all and (max-width: 370px) {
    .Card__Preview {
        width: 100px;

    }

    .Card__mask {
        width: 120px;
        height: 120px;
    }

    .Card__Preview > img {
        width: 90px;
    }
}