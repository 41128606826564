.Avatar__list {
    display: grid;
    grid-template-columns: 64px 64px 64px 64px;
    grid-template-rows: auto;
    grid-gap: 8px;

    width: auto;
    margin: auto;
    margin-bottom: 24px;
}

.Avatar {
    display: block;
    width: 67px;
    height: 67px;

    border: 2px solid #e2e2e2;
    border-radius: 8px;
    cursor: pointer;
}

@media all and (max-width: 500px) {
    .Avatar {
        width: 50px;
        height: 50px;
    }
}
