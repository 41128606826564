.Statement {
    display: block;
    margin: 28px 0 20px 0;

    color: #313131;

    font-family: "Circular Std Black";
    font-size: 20px;
    line-height: 25px;

    transition: 0.2s;
}

@media all and (max-width: 500px) {
    .Statement {
        font-size: 16px;
        margin: 10px 0 2px 0;
    }
}