.Question {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;

    padding-bottom: 24px;

    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #fdac4d;

    transition: 0.2s;
}

.Question > strong {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Question > strong, .Question__current {
    font-family: "Circular Std Bold";
    font-size: 27px;
    line-height: 28px;
}

.Question__current {
    margin: 0 6px;
}

@media all and (max-width: 500px) {
    .Question {
        font-size: 12px;
        padding-bottom: 12px;
    }

    .Question > strong {
        font-size: 20px;
    }
}