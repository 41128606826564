@font-face {
  font-family: "Circular Std Book";
  src: url("Assets/CircularStd-Book.woff") format("woff");
}
@font-face {
  font-family: "Circular Std Bold";
  src: url("Assets/CircularStd-Bold.woff") format("woff");
}
@font-face {
  font-family: "Circular Std Black";
  src: url("Assets/CircularStd-Black.woff") format("woff");
}

body {
  margin: 0;
  font-family: 'Circular Std Book', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #efefef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.no_select {
  height: 100%;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}