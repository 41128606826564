.ExpCard__Preview {
    display: block;
    width: 120px;
    height: 100%;
}

.ExpCard__mask {
    position: absolute;

    width: 140px;
    height: 140px;

    transform: translateX(-15%) translateY(50%);

    left: 0;
    right: auto;
    top: auto;
    bottom: 0;

    border-radius: 50%;
    background-color: #66B2DF;
}

.ExpCard__Preview > img {
    position: absolute;
    width: fit-content;
    /*top: calc(50% + 15px);*/
    left: 0;
    bottom: 0;
    /*transform: translateY(-50%);*/
}
