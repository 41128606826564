#View__Emoji-puzzle {
    background: linear-gradient(to right, #fa9d44 0%, #ffb852 100%);
}

.Emoji__Lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    pointer-events: none;
}

.Emoji__Line {
    stroke-width: 3px;
    stroke: #FDAC4C;
}

.fill {
    height: 100%;
}