.View__content {
    padding: 44px;
}

.View__content .ProgressBar {
    width: 60%;
    margin: 0 auto 34px auto;
}

.End__Congrats {
    font-family: "Circular Std Bold";
    font-size: 25px;
    line-height: 28px;
    letter-spacing: 1px;
    text-align: center;

    margin: 21px 0 44px 0;
}

.End__Score {
    margin: 16px auto 28px auto;
    font-family: "Circular Std Book";
    font-size: 16px;
    font-weight: 100;
    line-height: 22px;
    text-align: center;
    width: 206px;
}

.End__Won {
    font-family: "Circular Std Bold";
    font-size: 16px;
    line-height: 26px;
    text-align: center;
}

.End__percent {
    font-family: "Circular Std Bold";
    font-size: 31px;
    color: #fdac4c;
}