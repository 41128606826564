.Card {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;

    width: calc(100% - 32px);
    height: auto;

    padding: 24px;
    margin: 16px 16px;

    box-shadow: 0 6px 12px 0 rgba(105,185,227,0.49);
    background-color: #ffffff;
    border-radius: 16px;

    overflow: hidden;
}

.Card:first-of-type {
    margin-top: -90px;
}