.exp-screen {
    min-height: 100%;
    background: linear-gradient(49.75deg, #E9F0F4 0%, #FBFBFB 100%);
    padding-bottom: 30px;
}

.exp-header {
    background: transparent;
    color: #656565;
    height: 80px;
}

.exp-header-title {
    color: #656565;
}

.caret-left {
    filter: invert(1);
}

.exp-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 40px;
    text-align: left;
}

.exp-content__text {
    color: #313131;
    font-family: "Circular Std Bold";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 20px;
    flex: 1;
}

.exp-radio {
    border: 1px solid #D6D6D6;
    border-radius: 10px;
    height: 20px;
    width: 20px;
    position: relative;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    /*background-color: #eee;*/
}

.checkmark:after {
    content: "";
    position: absolute;
    display: block;
}

.exp-radio .checkmark:after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.ExpCard.active {
    background: linear-gradient(90deg, #559EDF 0%, #69B9E3 100%);
}

.ExpCard.active .exp-content__text {
    color: white;
}

.ExpCard.active .exp-radio {
    background-color: #FBAB56;
    border-color: #FBAB56;
}


/* TODO move elsewhere */
.text-center {
    text-align: center;
}

.mt-0 {
    margin-top: 0;
}


@media all and (max-height: 630px) {
    .ExpCard {
        height: 80px !important;
        min-height: 80px !important;
    }

    .ExpCard__Preview > img {
        width: 80px !important;
    }

    .ExpCard__mask {
        width: 100px !important;
        height: 100px !important;
    }
}