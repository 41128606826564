.TotalScore {
    width: 60%;
    padding: 24px;
    margin: 0 0 32px 0;

    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.12);

    text-align: center;
}

.TotalScore__Score {
    display: block;
    margin-bottom: 8px;

    font-size: 2em;
    font-weight: 600;
}

@media all and (max-width: 420px) {
    .TotalScore {
        padding: 12px;
    }

    .TotalScore__Score {
        font-size: 26px;
    }
}