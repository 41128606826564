.Popup__Title {
    margin-bottom: 29px;

    color: #313131;
    font-size: 1.5em;
    text-align: center;
    font-family: "Circular Std Bold";
}

.Popup__title__colored {
    color: #fdac4c;
}