.Victory__Your-score {
    padding: 20px;

    font-size: 17px;
    font-weight: 100;
    text-transform: uppercase;
    text-align: center;
}

#View__victory {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 20px;

    height: 100%;
}

.Victory__Great {
    font-size: 36px;
    margin: 0;
    text-align: center;
}

.Victory__Trophy {
    display: block;
    width: 100%;
    margin: -60px auto 0 auto;
}

.Victory__Answers {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin: 0 auto 10px auto;
}

#View__victory > .TotalScore {
    margin-bottom: 6px;
}

.Victory__Icon {
    width: 36px;
    height: 37px;
    margin: 13px auto;
}

.Victory__Answers__Good, .Victory__Answers__Wrong {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 80px;
    margin: 0 30px;
}

.Victory__Label {
    font-size: 0.8em;
    font-weight: 100;
    text-transform: uppercase;
    text-align: center;
}

.Victory__Answer__Count {
    text-align: center;
    margin: 14px auto;
    font-size: 1.6em;
    font-weight: bold;
}

.Victory__Separator {
    height: 107px;
    width: 1px;
    background-color: white;
    opacity: 0.11;
}

@media all and (max-width: 420px) {
    .Victory__Your-score {
        font-size: 15px;
    }

    .Victory__Great {
        font-size: 26px;
    }
}