.TextField {
    display: block;
    width: 100%;
    height: 53px;

    margin: 32px auto;
    padding: 24px;

    border-radius: 40px;
    border: 2px solid #D5DEE1;
    background-color: white;
    /*box-shadow: 0 0 15px 0 rgba(253,172,76,0.41);*/
    outline: none;

    font-family: 'Circular Std Book';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 20px;
    color: #313131;
}

.TextField:focus {
    border: 2px solid #fdac4c;
    box-shadow: 0 0 15px 0 rgba(253,172,76,0.41);
}
