#View__true-false {
    background: linear-gradient(to right, #fa9d44 0%, #ffb852 100%);
}

.View__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
}

.bottom_buttons { flex-direction: column; }

.View__buttons > Button {
    margin: 8px 14px;
}