.Button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 200px;
    height: 48px;
    min-height: 48px;

    color: white;
    background: linear-gradient(315deg, #FFB852 0%, #FA9D44 100%);
    box-shadow: 0 8px 24px 0 rgba(48,27,2,0.15);

    outline: none;
    border: none;
    border-radius: 48px;

    font-family: 'Circular Std Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;

    cursor: pointer;
    transition: 0.2s;
}

.Button:hover {
    box-shadow: 0 0 1px 6px #feb55155;
}

.true {
    background: #40b19f;
}

.true:hover {
    box-shadow: 0 0 1px 6px #40b19f55;
}

.false {
    background: #e16a6a;
}

.false:hover {
    box-shadow: 0 0 1px 6px #e16a6a55;
}

.is-large {
    width: 100%;
    height: 64px;
    min-height: 64px;
    font-size: 1.05em;
}

.waiting {
    visibility: hidden;
}

.Card .Button {
    margin-left: auto;
}

@media all and (max-width: 550px) {
    .Button {
        width: 100%;
    }

    .Card .Button {
        width: 80%;
    }
}

.help {
    display: inline-block;
    text-align: center;
    float: right;
    padding: 2px 4px;
    border: 1px solid #000000;
    border-radius: 100px;
    background: #ffffff;
    font: 12px arial;
    color: #000000;
    text-transform: none;
    width: 250px;
    height: 48px;
}

.help:disabled{
    background-color: lightgrey;
    box-shadow: none;
}

.help:disabled:before{
    content:  "\0000a0";
    display: inline-block;
    height: 24px;
    width: 24px;
    line-height: 24px;
    margin: 0 4px -6px -4px;
    position: relative;
    top: 0;
    left: -2px;
    background: url("../../Assets/indice.svg") no-repeat left center transparent;
    background-size: 100% 100%;
}

.help:before{
    content:  "\0000a0";
    display: inline-block;
    height: 24px;
    width: 24px;
    line-height: 24px;
    margin: 0 4px -6px -4px;
    position: relative;
    top: 0;
    left: -2px;
    background: url("../../Assets/indice.svg") no-repeat left center transparent;
    background-size: 100% 100%;
}

.next {
    text-align: center;
    vertical-align: middle;
    padding: 2px 4px;
    background: white;
    font: 12px arial;
    color: #000000;
    text-transform: none;
    box-shadow: none;
    width: auto;
    white-space: nowrap;
    height: 48px;
}

@media all and (max-width: 350px) {
    .help::before {
        background-size: 70% 70%;
        background-position: 100% center;
    }

    .next {
        display: flex;
        flex-direction: row;
        font-size: 11px;
    }
}