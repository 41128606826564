html {
  font-size: 14px !important;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(14px + 2 * ((100vw - 320px) / 680)) !important;
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 16px !important;
  }
}

html,
body,
#root,
.View {
  display: block;
  position: relative;
  overflow-x: hidden;

  width: 100%;
  height: 100%;
}

.View {
  width: 512px;
  height: 100%;
  min-height: 100%;
  margin: auto;

  background-color: white;
}

.Popup {
  width: 512px;
  margin: auto;
}

.View__Purple {
  height: 100%;
  background: linear-gradient(128.08deg, #4E2A7A 0%, #743EB6 100%);
  color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  background: linear-gradient(to right, #559edf 0%, #69b9e3 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;

  padding-bottom: 80px;
}

.Header-title {
  font-size: 17px;
  line-height: 22px;

  margin: 8px auto;

  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.Header-description {
  display: block;
  width: 60%;
  margin: 24px auto;

  font-size: 16px;
  line-height: 20px;
}

.App-link {
  color: #61dafb;
}

.Section {
  position: relative;
  width: 100%;

  padding: 16px 0 128px 0;
  color: #313131;
  background-color: white;
}

.mask {
  position: absolute;
  width: 100%;
  height: 100px;

  top: 0;
  left: 0;
  right: 0;

  z-index: -1;

  background: linear-gradient(to right, #559edf 0%, #69b9e3 100%);
}

.hidden {
  opacity: 0;
}

.none {
  display: none !important;
}

.mask::after {
  position: absolute;
  content: '';

  width: 100%;
  height: 100%;

  z-index: 1;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: #ffffff;
  border-radius: 48px 48px 0 0;
}

.description {
  margin-bottom: 36px;
}

.Score__Section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 0 auto 24px auto;
}

.Score__Section > img {
  display: block;
  width: 54px;
  height: 54px;
  margin-right: 24px;

  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 1px 6px #ffffff33;
  cursor: pointer;
}

.View__Profile-information-username {
  font-size: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media all and (max-width: 550px) {
  .View, .Popup {
    width: 100%;
  }
}