.Card__Content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 10px;

    text-align: left;
}

@media all and (max-width: 370px) {
    .Card__Content {
        width: 80%;
    }
}