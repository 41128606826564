.View__title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 24px 16px;
    color: white;
}

.View__title-bar-title {
    font-size: 17px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.hamburger-link {
    display: flex;
    align-items: center;
    width: 16px;
    height: 16px;
}

.hamburger {
    display: block;
    position: relative;
}

.hamburger,
.hamburger::before,
.hamburger::after {
    width: 16px;
    height: 1px;
    background: white;
}

.hamburger::before,
.hamburger::after {
    display: block;
    position: absolute;
    content: '';
}

.hamburger::before { top: -6px; }
.hamburger::after { bottom: -6px; }

.View__title-bar-information {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;
    font-size: 0.9em;

    border: 1px solid white;
    border-radius: 50%;
}