.Emoji {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 86px;
    height: 86px;
    margin: 24px;

    padding: 16px;

    border-radius: 7px;

    font-size: 52px;
    z-index: 99;
}

.Emoji__Selected {
    border: 3px solid #fdac4c;
}
