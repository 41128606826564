.Reward {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 512px;
    padding: 16px 16px 10px 16px;

    z-index: 1030;

    -webkit-transform: translateX(-50%) translateZ(0);
    -o-transform: translateX(-50%) translateZ(0);
    transform: translateX(-50%) translateZ(0);

    top: auto;
    left: 50%;
    right: auto;
    bottom: 0;

    text-align: left;

    color: white;
    background: linear-gradient(to right, #4e2a7a 0%, #733db4 100%);
}

.Reward__Content {
    display: block;
    width: 100%;
}

.Reward__Label {
    display: block;
    margin-bottom: 12px;
    font-family: "Circular Std Bold";
    font-size: 12px;
    text-transform: uppercase;
}

.Reward__Preview {
    display: block;
    width: 110px;
}

.Reward__Icon {
    position: absolute;
    top: -68px;
    left: -40px;
}

@media all and (max-width: 550px) {
    .Reward {
        width: 100%;
        left: 0;
        right: 0;
        transform: none;
    }
}