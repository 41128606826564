.ExpCard {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;

    height: auto;
    min-height: 120px;
    width: 100%;

    margin: 0 0 20px;
    padding: 24px;

    border-radius: 15px;
    background: #ffffff;
    box-shadow: 0 8px 24px 0 rgba(51,53,54,0.15);

    overflow: hidden;
    transition: 0.3s;
}
