.Choices__Choice {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;

    width: 130px;
    height: 47px;
    padding: 8px;

    margin: 4px;

    font-family: "Circular Std Bold";
    font-size: 15px;
    color: #313131;
    background-color: white;

    border-radius: 5px;
    border: 2px solid #eeeeee;
    cursor: pointer;
}

.wrong {
    border-color: #E16A6A;
    cursor: initial !important;
}

.wrong::after {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: 'x';

    right: -8px;
    top: -8px;
    width: 20px;
    height: 20px;

    background-color: #e16a6a;
    color: white;
    font-size: 12px;
    border-radius: 12px;

}

.large {
    width: 100%;
}

.Choice__hidden {
    visibility: hidden;
    pointer-events: none;
}

.disabled {
    border: 2px solid #bbbbbb;
    opacity: 0.5;
    border-radius: 5px;
    background-color: rgba(110,110,110,0.13);
    color: #A5A5A5;
    cursor: initial;
}

@media all and (max-width: 500px )  {
    .Choices__Choice {
        width: 100%;
        height: 38px;
        font-size: 12px;
    }
}