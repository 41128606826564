.Thumbnail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    height: 100px;
    padding: 24px;
    margin: 16px;

    background-color: white;
}