.ExpCard__Content {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 10px;

    justify-content: space-between;

    text-align: left;
}
