.IconButton {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 46px;
    width: 288px;

    margin: 52px auto 26px auto;

    text-decoration: none;
    border-radius: 31px;
    background: linear-gradient(315deg, #FFB852 0%, #FA9D44 100%);
    box-shadow: 0 8px 8px 0 rgba(169,103,25,0.15);
}

.IconButton__Icon {
    position: absolute;
    left: -10px;
    pointer-events: none;
}

.IconButton__Content {
    font-family: "Circular Std Book";
    margin-left: 110px;
    width: 125px;
    font-size: 14px;
    color: white;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
}