.Text__Missing-word {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;

    width: 97px;
    height: 47px;

    margin: 4px;

    font-family: "Circular Std Bold";
    font-size: 15px;
    color: #313131;
    border-radius: 5px;
    border: 2px dashed #fdac4c;
}

.good {
    border-style: solid;
}

.good::after {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: '✓';

    right: -8px;
    top: -8px;
    width: 20px;
    height: 20px;

    background-color: #fdac4c;
    color: white;
    font-size: 10px;
    border-radius: 12px;

}

.long {
    width: 100%;
}

.missing_word_hover {
    border-style: solid;
    background-color: #fdac4c44;
}

@media all and (max-width: 500px) {
    .Text__Missing-word {
        width: 72px;
        height: 38px;
        font-size: 12px;
    }

    .Choices .Text__Missing-word {
        width: 100%;
    }
}